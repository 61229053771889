<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table" @selection-change="selectionChange">
          <div slot="ctrl-button">
            <!-- <el-button class="btn" @click="independentRecordOrder">提交</el-button> -->
            <el-button class="btn" @click="onAdd">+ 新增</el-button>
            <el-button plain @click="terminateContract" style="float:right">终止合同</el-button>
            <el-button plain @click="supplementaryContract" style="float:right">合同续约</el-button>
          </div>
        </ayl-table>
      </div>
    </div>
    <ayl-dialog
      :visible.sync="isShowDialog"
      :viewUrl="viewUrlForDialog"
      v-model="dataForDialog"
      :async="true"
      :showBottomBtn="false"
    ></ayl-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const vm = this;
    return {
      common_getBidNameAndIdList: [true, 0, null], //标段管理下拉
      common_listFirstParty: [true, 1, null], //甲方单位下拉

      //弹窗有关
      viewUrlForDialog: ``,
      isShowDialog: false,
      dataForDialog: null,

      nav: [{ name: "标段合同" }],

      idList: [],

      table: {
        api: vm.$api_hw.districtInfo_contractPage,
        query: {
          bidId: null, //标段ID，标段选择下拉框value
          contractStatus: null, //合同状态 0-在用、1-终止、2-续约
          partyA: null, //甲方单位，甲方单位选择下拉框value
          queryContent: null, //查询内容 合同编号/合同名称（模糊匹配）
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "bidId",
            placeholder: "请选择",
            option: [],
          },
          {
            type: "select",
            title: "甲方单位",
            model: "partyA",
            placeholder: "请选择",
            option: [],
          },
          {
            type: "select",
            title: "合同状态",
            model: "contractStatus",
            placeholder: "请选择",
            option: vm.$enums_hw.contractStatusOption.list,
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "合同编号/合同名称",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "合同编号",
            showTooltip: true,
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true,
                    },
                    on: {
                      click: vm.previewDistrictDetails.bind(this, ctx.row),
                    },
                  },
                  ctx.row.contractNum || "- -"
                ),
              ]);
            },
          },
          {
            title: "合同名称",
            // width: "180px",
            showTooltip: true,
            key: "contractName",
          },
          {
            title: "关联标段",
            key: "bidName",
            // filter: "str2ymd",
            width: "160px",
          },
          {
            title: "甲方单位",
            // width: "120px",
            key: "partyAUnit",
          },
          {
            title: "甲方项目联系人",
            // width: "180px",
            key: "partyAContact",
          },
          {
            title: "甲方联系方式",
            key: "partyAPhone",
          },
          {
            title: "乙方项目联系人",
            key: "partyBContact",
          },
          {
            title: "乙方项目联系方式",
            key: "partyBPhone",
          },
          {
            title: "合同状态",
            key: "contractStatusDes",
          },
          {
            title: "操作",
            width: "65px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true,
                    },
                    on: {
                      click: vm.editDistrict.bind(this, ctx.row),
                    },
                  },
                  "编辑"
                ),
              ]);
            },
          },
        ],
      },
    };
  },
  methods: {
    //新增
    onAdd() {
      //传views的url
      this.viewUrlForDialog =
        "/district-management/district-contract/add-contract";
      //传参给弹窗
      this.dataForDialog = {};
      this.isShowDialog = true; //显示弹窗
    },

    /**
     * 编辑合同
     */
    editDistrict(val) {
      // 传views的url
      this.viewUrlForDialog =
        "/district-management/district-contract/edit-contract";
      //传参给弹窗
      this.dataForDialog = {
        id: val.contractId,
      };
      this.isShowDialog = true; //显示弹窗
    },
    /**
     * 查看合同信息
     */
    previewDistrictDetails(val) {
      this.$router.push({
        path: "/district-management/district-contract/info-contract",
        query: { id: val.contractId },
      });
    },
    // 获取选中的表单
    selectionChange(val) {
      this.idList = val.map((item) => {
        return item.contractId;
      });
    },

    // 合同续约
    supplementaryContract() {
      let that = this;
      if (this.idList.length < 1) {
        this.$notify({
          title: "警告",
          message: "请选择要续约的合同",
          type: "warning",
        });
      } else {
        this.$confirm("请谨慎操作，是否选择续约合同?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let list = [];
          let cunst = 0;
          that.table.data.forEach((item) => {
            if (new Set(that.idList).has(item.contractId)) {
              if (item.contractStatus !== 1) {
                list.push(item.contractId);
              } else {
                cunst++;
              }
            }
          });
          if (cunst > 0) this.$message.error("已经终止的合同无法进行续约");
          if (list.length < 1) return;
          this.$api_hw
            .districtInfo_contractRenew({ idList: list })
            .then((res) => {
              this.$search(this.table);
              this.$notify({
                title: "成功",
                message: "合同续约成功!",
                type: "success",
              });
            });
        });
      }
    },
    // 终止合同
    terminateContract() {
      let that = this;
      if (this.idList.length < 1) {
        this.$notify({
          title: "警告",
          message: "请选择要终止的合同",
          type: "warning",
        });
      } else {
        this.$confirm("请谨慎操作，是否要终止选中的合同?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let list = [];
          let cunst = 0;
          that.table.data.forEach((item) => {
            if (new Set(that.idList).has(item.contractId)) {
              if (item.contractStatus !== 1) {
                list.push(item.contractId);
              } else {
                cunst++;
              }
            }
          });
          if (cunst > 0) this.$message.error("已经终止的合同无法再次进行终止");
          if (list.length < 1) return;
          this.$api_hw
            .districtInfo_contractTerminate({ idList: list })
            .then((res) => {
              this.$search(this.table);
              this.$notify({
                title: "成功",
                message: "合同终止成功!",
                type: "success",
              });
            });
        });
      }
    },
  },
  async activated() {
    // 获取列表信息
    await this.$search(this.table);
  },
  async mounted() {
    let self = this;
    BUS.$on(BUSEVENT.REFRESH_DISTRICT_CONTRACT, () => {
      self.$search(this.table);
    });
  },
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

</style>
